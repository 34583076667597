
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

body {
    margin: 0;
    font-family: 'Reenie Beanie', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
    font-family: 'Reenie Beanie', cursive, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
