@import url(https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap);
html, body, #root, .of_community, .main_content, .content_div {
    min-height: 100% !important;
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;
    background: linear-gradient(125.7deg,#000000 .64%,#030102 59.22%,#404040 114.62%);
    color: #ffffff;
    font-family: 'Blinker', sans-serif;
}

p {
    margin: unset;
}

.padding {
    padding: 26px 60px;
}

.of_community .connect_button {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 34px;
    line-height: 130%;
    color: #000000;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 22px;
    text-transform: unset;
    box-shadow: unset;
}

.of_community .connect_button img {
    width: 60px;
    margin-right: 26px;
}

.hash_text {
    font-family: 'Blinker', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
    color: #FFFFFF;
    max-width: 200px;
    display: -webkit-flex;
    display: flex;
}

.hash_text .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: -1px;
}

.scroll_bar {
    overflow: auto;
}

.dialog > div:first-child {
    background: rgba(255, 255, 255, 0.14);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
}

.dialog div[role='dialog'] {
    width: 600px;
    background: #000000;
    -webkit-backdrop-filter: blur(35px);
            backdrop-filter: blur(35px);
    border-radius: 10px;
    color: #ffff;
}

.dialog > div .content {
    padding: 50px;
}

.dialog h2 {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: #FFFFFF;
    margin: 0;
}

.dialog .note {
    font-family: 'Blinker', sans-serif;
    font-size: 18px;
    line-height: 130%;
    color: #FFFFFF;
}

.dialog .footer {
    padding: 0 50px 50px;
    -webkit-justify-content: center;
            justify-content: center;
}

.dialog .footer button {
    background: #0088CC;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
    border-radius: 5px;
    font-family: 'Blinker', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    text-transform: capitalize;
    padding: 10px 30px;
}

.cursor {
    cursor: pointer;
}

/* scroll bar */
html::-webkit-scrollbar,
.table > div > div::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

html::-webkit-scrollbar-thumb,
.table > div > div::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    border: 0 solid #fff;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, .24);
    border-radius: 20px;
    background: #fff;
}

@media (max-width: 1025px) {
    .padding {
        padding: 20px 40px;
    }

    .of_community .card > button {
        font-size: 28px;
        padding: 14px;
    }

    .of_community .card > button img {
        width: 50px;
        margin-right: 20px;
    }
}

@media (max-width: 769px) {
    .padding {
        padding: 20px;
    }
}

@media (max-width: 426px) {
    .of_community .card > button {
        font-size: 20px;
    }

    .of_community .card > button img {
        width: 40px;
        margin-right: 10px;
    }

    .dialog > div .content {
        padding: 20px;
    }

    .dialog div[role='dialog'] {
        margin: 10px;
    }
}

.home .card {
    background: rgba(255, 255, 255, 0.14);
    -webkit-backdrop-filter: blur(100px);
            backdrop-filter: blur(100px);
    border-radius: 10px;
    padding: 80px 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    text-align: left;
}

.home .card h2 {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
    margin: 0 0 10px;
}

.home .card .info {
    font-family: 'Blinker', sans-serif;
    font-size: 24px;
    line-height: 130%;
}

.home .card.telegram_card {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 50px;
}

.home .telegram_card .connect_button {
    margin-top: 50px;
}

.connect_dialog form {
    margin: 50px 0 20px;
    text-align: center;
}

.connect_dialog form .text_field {
    margin: 0;
    width: 80%;
}

.otp_dialog .user_name {
    margin: 60px 0;
}

.otp_dialog .user_name .image {
    background: #0088CC;
    border: 4px solid #FFFFFF;
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 38px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 10px;
}

.otp_dialog .user_name > p {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 130%;
    color: #FFFFFF;
}

.otp_dialog .otp .divider {
    background: #515151;
    width: 2px;
    height: 20px;
    margin: 0 40px 0 10px;
}

.otp_dialog .otp .copy_button {
    border: 1px solid #515151;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #515151;
    padding: 0 20px;
    width: -webkit-max-content;
    width: max-content;
    height: 38px;
    text-transform: unset;
    min-width: -webkit-max-content;
    min-width: max-content;
}

.otp_dialog .otp .copy_button > span:first-child {
    margin-bottom: 4px;
}

.otp_dialog .content .link,
.otp .otp_info > span {
    color: #0088CC;
}

.otp .copy_field {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    text-align: left;
    margin-top: 50px;
}

.otp .copy_field > p {
    font-family: 'Blinker', sans-serif;
    font-size: 18px;
    line-height: 136%;
    color: #FFFFFF;
}

.otp .otp_info {
    font-family: 'Blinker', sans-serif;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
}

.otp .otp_number {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 30px;
}

.otp .otp_number > p {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
    color: #0088CC;
    letter-spacing: 30px;
}

.home .no_data {
    width: 100%;
    height: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
}

.content_div .view_all {
    background: #FFFFFF;
    border-radius: 50px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #000000;
    padding: 8px 20px;
}

.content_div .view_all:hover {
    background: #FFFFFF;
}

@media (max-width: 1441px) {
    .home .card {
        -webkit-flex-direction: column;
                flex-direction: column;
        text-align: center;
        padding: 50px;
    }
}

@media (max-width: 769px) {
    .home .card {
        padding: 50px 20px;
    }

    .content_div .view_all {
        font-size: 16px;
    }
}

@media (max-width: 426px) {
    .otp .copy_field {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .otp .otp_info {
        word-break: break-word;
        text-align: center;
    }

    .otp_dialog .otp .copy_button {
        margin: 10px 0 0;
    }

    .otp_dialog .user_name {
        margin: 40px 0;
    }

    .otp .otp_number > p {
        letter-spacing: 20px;
    }

    .otp .otp_number {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .otp_dialog .otp .divider {
        display: none;
    }

    .connect_dialog form .text_field {
        width: 100%;
    }

    .content_div .view_all {
        display: none;
    }
}

.nav_bar {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.nav_bar > img {
    width: 140px;
}

.nav_bar .right_content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.nav_bar .select_fields {
    background: linear-gradient(
            91.04deg, rgba(0, 0, 0, 0.3) -2.67%, rgba(0, 0, 0, 0.228) 48.93%, rgba(0, 0, 0, 0.3) 99.58%);
    border-radius: 50px;
    padding: 10px 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 10px;
}

.nav_bar .divider {
    width: 2px;
    height: 20px;
    background: #fff;
    display: inline-block;
    margin: 0 20px;
}

.nav_bar .disconnect_button,
table .disconnect_button {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.99) 0%, rgba(240, 231, 231, 0.5544) 100%);
    box-shadow: 0 4px 20px rgb(0 0 0 / 25%);
    color: #000000;
    border-radius: 50px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    padding: 10px 20px;
    text-transform: unset;
}

.nav_bar .disconnect_button:hover {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.99) 0%, rgba(240, 231, 231, 0.5544) 100%);
}

.nav_bar .disconnect_button .circular_progress {
    color: #FFFFFF;
    margin-left: 20px;
}

.nav_bar .select_fields .copy_button {
    width: -webkit-max-content;
    width: max-content;
    background: rgba(255, 255, 255, 0.3);
    color: #000000;
    border-radius: 50px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    padding: 0 20px;
    text-transform: unset;
    height: 30px;
    margin-left: 10px;
}

.nav_bar .select_field > div > div {
    font-size: 20px;
}

.nav_bar .select_fields .copy_button > span:first-child {
    margin-bottom: 2px;
}

.nav_bar .horizontal_tabs {
    width: unset;
    background: unset;
    box-shadow: unset;
}

.nav_bar .horizontal_tabs .tab {
    background: linear-gradient(
            91.04deg, rgba(0, 0, 0, 0.3) -2.67%, rgba(0, 0, 0, 0.228) 48.93%, rgba(0, 0, 0, 0.3) 99.58%);
    border-radius: 50px;
    padding: 10px 24px;
    font-family: 'Blinker', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    text-transform: unset;
    opacity: 1;
    margin: 0 10px;
    min-width: -webkit-max-content;
    min-width: max-content;
}

.nav_bar .horizontal_tabs .tab:first-child {
    margin-left: 0;
}

.nav_bar .horizontal_tabs .tab:hover,
.nav_bar .horizontal_tabs .tab.active_tab {
    background: #FFFFFF;
    color: #000000;
}

.nav_bar .toggle_button,
.nav_bar .right_content .back_button {
    display: none;
}

.nav_bar .token_name {
    font-family: 'Blinker', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
    color: #FFFFFF;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@media (max-width: 1441px) {
    .nav_bar .horizontal_tabs .tab,
    .nav_bar .select_field > div > div {
        font-size: 18px;
    }

    .of_community .select_fields .select_field:first-child {
        max-width: unset;
        min-width: unset;
    }

    .of_community .select_fields .select_field:nth-child(3) {
        max-width: 250px;
    }
}

@media (max-width: 1120px) {
    .nav_bar .horizontal_tabs {
        margin-bottom: 10px;
    }

    .nav_bar.disconnected_nav .horizontal_tabs {
        margin: unset;
    }
}

@media (max-width: 769px) {
    .nav_bar .right_content {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-end;
                align-items: flex-end;
    }

    .nav_bar .disconnect_button {
        margin-top: 10px;
    }

    .nav_bar .toggle_button {
        display: unset;
    }

    .right_content .back_button {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        cursor: pointer;
        width: 100%;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        padding: 20px 48px 0 0;
    }

    .right_content .back_button .icon {
        width: 40px;
    }

    .nav_bar .right_content.show .back_button {
        display: -webkit-flex;
        display: flex;
    }

    .nav_bar .right_content {
        display: none;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
    }

    .nav_bar .right_content.show {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        z-index: 1;
        padding: 20px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(14, 14, 14, 0.2);
        -webkit-backdrop-filter: blur(40px);
                backdrop-filter: blur(40px);
        -webkit-animation-name: fadeInDown;
                animation-name: fadeInDown;
    }

    .nav_bar .horizontal_tabs {
        margin-top: 60px;
    }

    .nav_bar .horizontal_tabs .tabs_content {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .nav_bar .horizontal_tabs .tab {
        margin: 0 0 10px;
        width: 200px;
    }
}

@media (max-width: 426px) {
    .nav_bar .select_fields {
        width: 100%;
        -webkit-flex-direction: column;
                flex-direction: column;
        background: unset;
        border-radius: unset;
        padding: 0;
    }

    .of_community .nav_bar .select_field {
        background: linear-gradient(
                91.04deg, rgba(0, 0, 0, 0.3) -2.67%, rgba(0, 0, 0, 0.228) 48.93%, rgba(0, 0, 0, 0.3) 99.58%);
        border-radius: 50px;
        margin: 5px 0;
        width: 100%;
        max-width: 100%;
        padding: 0 20px;
    }

    .of_community .select_fields .select_field:nth-child(3) {
        max-width: 100%;
    }

    .of_community .nav_bar .select_field:last-child {
        margin-bottom: 0;
    }

    .nav_bar .divider {
        margin: 0 10px;
        display: none;
    }

    .nav_bar .right_content,
    .nav_bar .disconnect_button {
        width: 100%;
    }

    .nav_bar .horizontal_tabs {
        margin-bottom: unset;
        width: 100%;
    }

    .nav_bar .horizontal_tabs .tab {
        min-width: 100%;
        width: 100%;
    }
}

.copy_button {
    margin-left: 20px;
    width: 50px;
    height: 50px;
    padding: 0;
}

.copy_button .icon {
    width: 20px;
}

.token_details {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 20px;
}

.token_details .chip_info {
    text-align: center;
    margin-right: 40px;
}

.token_details .chip_info:last-child {
    margin-right: unset;
}

.token_details .chip {
    background: #FFFFFF;
    border-radius: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 10px 20px;
    margin: 10px 0;
}

.token_details .chip > img {
    margin-right: 20px;
}

.token_details .chip p {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 130%;
    color: #000000;
    width: -webkit-max-content;
    width: max-content;
}

.token_details .chip_info > p {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    width: -webkit-max-content;
    width: max-content;
    margin: auto;
}

.token_details .outline_button {
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    border-radius: 100px;
}

.token_details .buttons_div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.token_details .buttons_div > span {
    height: 20px;
    border: .5px solid #FFFFFF;
    margin: 0 10px;
}

@media (max-width: 1441px) {
    .token_details {
        margin: 40px 0 0;
        width: 100%;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .token_details .chip_info {
        margin-bottom: 20px;
    }
}

@media (max-width: 426px) {
    .token_details {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .token_details .chip_info {
        margin: 0 0 20px;
        width: 100%;
    }
}

.spinner {
    width: 70px;
    text-align: center;
    margin: auto;
}

.spinner > div {
    width: 10px;
    margin: 0 2px;
    height: 10px;
    background-color: #1923cf;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bounce_delay 1.4s infinite ease-in-out both;
            animation: bounce_delay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
}

@-webkit-keyframes bounce_delay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
                transform: scale(1.0);
    }
}

@keyframes bounce_delay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
                transform: scale(1.0);
    }
}

.delegate_dialog .content {
    text-align: left;
}

.delegate_dialog .content > h1 {
    font-family: 'Blinker', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    margin: unset;
    margin-bottom: 65px;
}

.delegate_dialog .content > p {
    font-family: 'Blinker', sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin-bottom: 6px;
}

.delegate_dialog .text_field {
    margin: unset;
}

.delegate_dialog .select_field {
    margin: unset;
    margin-bottom: 30px;
}

.delegate_dialog .select_field > div > div {
    border: 1px solid #696969;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.delegate_dialog .select_field svg {
    fill: #696969;
    right: 20px;
}

.delegate_dialog .select_field .image,
.delegate_dialog .validator_name .image {
    background: #696969;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-right: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
}

.delegate_dialog .text_field > div {
    height: 50px;
}

.delegate_dialog .available_tokens {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 20px;
}

.delegate_dialog .available_tokens .heading {
    font-family: 'Blinker', sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #696969;
    margin-right: 20px;
}

.delegate_dialog .available_tokens .value {
    font-family: 'Blinker', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #0085FF;
    cursor: pointer;
}

.dialog.delegate_dialog .footer button {
    text-transform: uppercase;
}

.dialog.delegate_dialog .footer button:disabled {
    opacity: 0.5;
}

.delegate_dialog .validator_name {
    border: 1px solid #696969;
    box-sizing: border-box;
    border-radius: 10px;
    height: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 400px;
    margin: auto;
    padding: 10px 20px;
}

.delegate_dialog .validator_name > p {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: #696969;
    -webkit-flex: 1 1;
            flex: 1 1;
    word-break: break-all;
    margin-right: 10px;
}

.result .content .heading {
    text-align: center;
}

.result .content h1 {
    margin-bottom: 30px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
}

.result .content .row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 15px;
}

.result .content .row > .hash_text {
    width: 150px;
}

.result .content .row p,
.result .content .row .hash_text {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #D3D3D3;
}

.result .hash_text.link {
    cursor: pointer;
}

.result .hash_text.link:hover {
    text-decoration: underline;
}

.result .content .row > .validator {
    text-align: right;
}

.pending img {
    width: 100px;
}

.of_community .select_field {
    margin: 0;
    min-width: 150px;
    width: auto;
    max-width: 300px;
}

.select_field input {
    color: #111;
}

.select_field > div {
    box-shadow: unset;
    border-radius: 23px;
}

.of_community .main_content .select_field > div:before,
.of_community .main_content .select_field > div:after,
.dialog .select_field > div:before,
.dialog .select_field > div:after,
.dialog .select_field > div:hover:before {
    border: unset;
    border-radius: 5px;
}

.select_field > div > div {
    height: -webkit-max-content;
    height: max-content;
    box-sizing: border-box;
    padding: 10px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 2px;
}

.select_field svg {
    fill: #FFFFFF;
}

.select_field > div > div:focus {
    background: unset;
}

/* scroll bar */
div[role='presentation'] > div[tabindex='-1']::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

div[role='presentation'] > div[tabindex='-1']::-webkit-scrollbar-thumb {
    border: 0 solid #fff;
    box-shadow: unset;
    border-radius: 20px;
    background: #fff;
}

div[role='presentation'][id='menu-validators'] > div[tabindex='-1'] {
    background: #1E1E1E;
    box-shadow: 0 0 50px rgb(0 0 0 / 50%);
    border-radius: 5px;
    max-height: 400px;
}

div[role='presentation'][id='menu-validators'] li {
    font-family: 'Blinker', sans-serif;
    font-size: 18px;
    line-height: 130%;
    color: #FFFFFF;
    padding: 8px 40px;
}

div[role='presentation'][id='menu-validators'] li .image {
    background: #696969;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-right: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

div[role='presentation'][id='menu-validators'] li:hover,
div[role='presentation'][id='menu-validators'] li[aria-selected='true'] {
    background: #000000;
}

.text_field {
    width: 100%;
    position: relative;
}

.text_field > div {
    height: 2.573rem;
    font-weight: 100;
}

.text_field > p {
    font-size: 12px;
    font-weight: 300;
    line-height: 30px;
    position: absolute;
    bottom: -20px;
}

.text_field input {
    padding: .429rem 1rem;
    height: 100%;
    box-sizing: border-box;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #696969;
}

.text_field fieldset {
    border-radius: 5px;
    border-color: #696969;
}

.ex_network .text_field > div:hover fieldset,
.dialog .text_field > div:hover fieldset {
    border-color: #696969;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
            appearance: none;
    margin: 0;
}

.text_field .error {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.text_field .error .icon {
    width: 16px;
    margin-bottom: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    border: unset;
    -webkit-text-fill-color: #76838f;
    caret-color: #76838f;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

/*search*/
.search_text_field .search_icons {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.search_text_field .search_icons .icon {
    width: 24px;
}

.search_text_field .icon-menu {
    width: 24px;
    fill: #ffffff80;
}

.search_text_field .search_icons .line {
    border: 1px solid #ffffff80;
    width: 2px;
    height: 20px;
    margin-left: 6px;
}

.search_text_field > div {
    background: linear-gradient(
            91.04deg, rgba(0, 0, 0, 0.3) -2.67%, rgba(0, 0, 0, 0.228) 48.93%, rgba(0, 0, 0, 0.3) 99.58%);
    border-radius: 50px;
}

.text_field.search_text_field fieldset {
    border: unset;
}

.text_field.search_text_field input {
    font-family: 'Blinker', sans-serif;
    font-size: 18px;
    line-height: 130%;
    color: #FFFFFF;
    opacity: 0.5;
}

.circular_progress {
    width: 100%;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.circular_progress.full_screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00000070;
    z-index: 9;
}

.circular_progress > div {
    color: #FFFFFF;
}

table .circular_progress > div {
    color: #3f51b5;
}

.table table {
    border-collapse: separate;
    border-spacing: 0 5px;
}

.table div[aria-label = 'Table Toolbar'] {
    display: none;
}

.table > div {
    background: transparent;
    box-shadow: unset;
}

.table thead th {
    border-bottom: unset;
    padding: 0 10px;
    text-align: center;
}

.table thead th > span {
    -webkit-justify-content: center;
            justify-content: center;
}

.table th button,
.table thead th > div {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    margin: auto;
    width: -webkit-max-content;
    width: max-content;
    text-align: center;
}

.table th button div {
    color: #ffffff;
}

.table th button svg {
    fill: #ffffff;
}

.table th button > span > div > div:last-child {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.table tbody tr td {
    background: #FFFFFF;
    border: unset;
    margin-bottom: 10px;
    padding: 12px;
    border-color: #ffff;
}

.table tbody tr td > div {
    text-align: center;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #696969;
}

.table tbody tr td:first-child {
    border-radius: 50px 0 0 50px;
}

.table tbody tr td:last-child {
    border-radius: 0 50px 50px 0;
}

.table tbody tr td[colspan='6'] {
    border-radius: 50px;
}

@media (max-width: 958px) {
    .table table {
        border-spacing: 0 10px;
        margin-top: unset;
    }

    .table tbody tr td {
        padding: 12px 20px;
        margin-bottom: -1px;
        border-radius: unset;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .table tbody tr td > div {
        text-align: left;
    }

    .table tbody tr td > div:last-child {
        text-align: right;
    }

    .table tbody tr td:first-child {
        border-radius: 10px 10px 0 0;
    }

    .table tbody tr td:last-child {
        border-radius: 0 0 10px 10px;
    }

    .table tbody tr td:first-child > div:first-child,
    .table tbody tr td:last-child > div:first-child {
        display: none;
    }

    .table tbody tr td:first-child > div:nth-child(2),
    .table tbody tr td:last-child > div:nth-child(2) {
        width: 100%;
    }
}

@media (max-width: 426px) {
    .table table {
        width: 100%;
    }

    .table table tbody,
    .table table tbody tr,
    .table tbody tr td {
        width: 100%;
    }

    .table tbody tr td > div {
        width: auto;
    }
}

.stake .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 30px;
}

.stake .heading .tabs {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.stake .tabs > p {
    cursor: pointer;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
    color: #ffffff80;
}

.stake .tabs > p.active {
    color: #ffffff;
}

.stake .tabs > span {
    border: 1px solid #FFFFFF;
    height: 30px;
    margin: 0 20px;
}

.stake .heading .text_field {
    margin: unset;
    width: 400px;
}

.stake .table {
    background: rgba(255, 255, 255, 0.14);
    -webkit-backdrop-filter: blur(100px);
            backdrop-filter: blur(100px);
    border-radius: 10px;
    margin-top: 16px;
    padding: 40px 58px;
}

.stake .table .validator {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 200px;
}

.table .validator .image {
    background: #EDEDED;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    margin-right: 20px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

/*.stake .table tbody tr td {*/
/*    cursor: pointer;*/
/*}*/

.stake .table .voting_power {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.table .voting_power > span {
    border: 1px solid #DCDCDC;
    height: 20px;
    margin: 0 10px;
}

.stake .table .actions {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.table .actions > button {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 50px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
}

.table .actions > button.delegate_button {
    border-color: #00FF0A;
}

.table .actions > button.un_delegate {
    border-color: #FF0000;
}

.table .actions > span {
    border: 1px solid #D2D2D2;
    margin: 0 10px;
    height: 20px;
}

.table .no_tokens {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #BDBDBD;
}

.table td .tokens {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #0085FF;
}

.table td .status {
    border: 1px solid #02D70A;
    box-sizing: border-box;
    border-radius: 50px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #02D70A;
    padding: 6px 12px;
}

.table td .status.red_status {
    border: 1px solid #ff0000;
    color: #ff0000;
}

.table tfoot td {
    border-bottom: unset;
}

@media (max-width: 1025px) {
    .stake .table {
        padding: 20px 30px;
    }

    .table td .status {
        width: -webkit-max-content;
        width: max-content;
        margin: auto;
    }
}

@media (max-width: 958px) {
    .stake .heading {
        margin-top: unset;
    }

    .stake .table .validator {
        width: 100%;
    }

    .table td .status {
        margin-right: unset;
    }

    .stake .table .voting_power {
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }

    .table td .tokens {
        text-align: right;
    }
}

@media (max-width: 769px) {
    .stake .table {
        background: unset;
        padding: 0;
        -webkit-backdrop-filter: unset;
                backdrop-filter: unset;
        border-radius: unset;
    }

    .stake .tabs > p {
        font-size: 30px;
    }
}

@media (max-width: 426px) {
    .stake .heading {
        overflow: auto;
    }

    .stake .tabs > p {
        width: -webkit-max-content;
        width: max-content;
    }

    .table .actions > span {
        display: none;
    }

    .stake .table .actions {
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .table .actions > button {
        padding: 5px 10px;
    }
}

@media (max-width: 375px) {
    .stake .table .actions {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .table .actions > button {
        width: 100%;
        margin: 4px 0;
    }
}

.proposal_dialog {
    height: 100%;
    width: 100%;
    margin-top: 30px;
}

.proposal_dialog .content {
    background: rgba(255, 255, 255, 0.14);
    -webkit-backdrop-filter: blur(100px);
            backdrop-filter: blur(100px);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: 80px;
    position: relative;
}

.proposal_dialog .content .close_button {
    position: absolute;
    right: 20px;
    top: -20px;
    box-shadow: unset;
    background: linear-gradient(#ffffff24 44%, transparent 50%);
}

.proposal_dialog .close_button .icon {
    width: 20px;
}

.proposal_dialog_section1 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.proposal_dialog_section1_header {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 48px;
    line-height: 130%;
    color: #FFFFFF;
    text-align: left;
}

.proposal_dialog_section1_status {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #000000;
    background: #02D70A;
    border: 1px solid #00A306;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 8px 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: 100px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.proposal_dialog_section1_status.voting_period {
    background: #79C7FF;
    border-color: #3B9BE0;
}

.proposal_dialog_section1_status.rejected {
    background: #FF0000;
    border-color: #C20000;
    color: #ffffff;
}

.proposal_dialog_section2 {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    text-align: left;
    margin-top: 30px;
}

.proposal_dialog_section3_left .pds3l_c2.vote_details {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.proposal_dialog_section2_content {
    height: 2.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    width: 100%;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
}

.proposal_dialog_section2_content.show_more {
    height: unset;
}

.proposal_dialog_section2_more {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
}

.proposal_dialog_section3 {
    margin-top: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.proposal_dialog_section3_left {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-top: 30px;
}

.proposal_dialog_section3_left .pds3l_c {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-bottom: 50px;
}

.proposal_dialog_section3_left .pds3l_c:nth-child(4) {
    margin-bottom: 20px;
}

.proposal_dialog_section3_left .pds3l_c1 {
    font-family: 'Blinker', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    width: 220px;
    text-align: left;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.proposal_dialog_section3_left .pds3l_c2 {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    text-align: left;
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
}

.proposal_dialog_section3_left .vp_cards {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.proposal_dialog_section3_left .vp_cards > p {
    background: #F3F3F3;
    border-radius: 100px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    padding: 8px 20px;
    margin-right: 20px;
}

.proposals .pds3l_c2.vote_details > div > p {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
}

.proposal_dialog_section3_left .vote_in_progress {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.proposals .pds3l_c2.vote_in_progress > div {
    width: 50%;
    margin-bottom: 30px;
}

.proposal_dialog_section3_right {
    width: 50%;
    text-align: left;
    margin-left: 20px;
}

.proposal_dialog .pds3r_heading {
    font-family: 'Blinker', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    margin-left: 10px;
}

.proposal_dialog .voting_card {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    width: 100%;
    margin-top: 8px;
    padding: 50px 60px 40px;
}

.proposal_dialog .voting_card label {
    margin-bottom: 10px;
}

.proposal_dialog .voting_card label > span:first-child {
    margin-right: 20px;
}

.proposal_dialog .voting_card label > span:last-child {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #000000;
}

.proposal_dialog .voting_card label svg {
    fill: #000000;
}

.voting_card .buttons_div {
    text-align: center;
    margin-top: 50px;
}

.proposal_dialog .voting_card .cancel_button {
    border: 2px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #000000;
    margin-right: 20px;
    padding: 10px 30px;
}

.proposal_dialog .voting_card .confirm_button {
    background: #000000;
    border-radius: 10px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 30px;
}

.proposal_dialog .already_voted {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-left: -40px;
}

.proposal_dialog .already_voted .icon {
    width: 34px;
    margin-right: 20px;
}

.proposal_dialog .already_voted > p {
    background: #000000;
    border: 1px solid #696969;
    box-sizing: border-box;
    border-radius: 100px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    padding: 8px 40px;
}

.proposal_dialog .vote_info {
    font-family: 'Blinker', sans-serif;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    color: #ffffff;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.proposal_dialog .circular_progress {
    height: 31px;
}

@media (max-width: 1025px) {
    .proposal_dialog_section3 {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .proposal_dialog_section3_right {
        width: 100%;
        margin: unset;
    }
}

@media (max-width: 769px) {
    .proposal_dialog .content {
        padding: 30px;
    }

    .proposal_dialog_section1 {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .proposal_dialog_section1_header {
        width: 100%;
    }

    .proposal_dialog_section1_status {
        margin: unset;
    }

    .proposal_dialog_section3_left .vp_cards {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .proposal_dialog .voting_card {
        padding: 30px;
    }

    .proposal_dialog_section3_left .vp_cards > p {
        margin: 2px;
    }

    .proposals .vote_details > div {
        width: 50%;
        margin-bottom: 30px;
    }

    .proposal_dialog .already_voted {
        margin-left: unset;
    }
}

@media (max-width: 426px) {
    .proposal_dialog_section1_header {
        font-size: 34px;
        text-align: center;
    }

    .proposal_dialog_section1_status {
        font-size: 16px;
        padding: 8px 20px;
    }

    .proposal_dialog_section3_left .pds3l_c {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-bottom: 30px;
    }

    .proposal_dialog_section3_left .pds3l_c2,
    .proposals .pds3l_c2 > div > p {
        font-size: 20px;
    }

    .proposal_dialog_section3_left .vp_cards > p {
        font-size: 14px;
    }

    .voting_card .buttons_div {
        margin-top: 30px;
    }

    .voting_card .buttons_div > button {
        width: 100%;
    }

    .proposal_dialog .voting_card .cancel_button {
        margin: unset;
    }

    .proposal_dialog .voting_card .confirm_button {
        margin-top: 10px;
    }

    .proposal_dialog .content {
        padding: 20px;
    }

    .proposals .vote_details > div,
    .proposals .pds3l_c2.vote_in_progress > div {
        width: 100%;
        margin-bottom: 20px;
    }

    .proposal_dialog_section3 {
        margin-top: 20px;
    }

    .proposal_dialog_section3_left .pds3l_c2.type {
        word-break: break-all;
    }

    .proposal_dialog .already_voted > p {
        font-size: 18px;
        line-height: 110%;
        padding: 8px 20px;
    }

    .proposal_dialog .already_voted .icon {
        display: none;
    }
}

.proposals .proposals_content {
    margin-top: 30px;
}

.proposals .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.proposals .heading .tabs {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.proposals .tabs > p {
    cursor: pointer;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
    color: #ffffff80;
}

.proposals .tabs > p.active {
    color: #ffffff;
}

.proposals .tabs > span {
    border: 1px solid #FFFFFF;
    height: 30px;
    margin: 0 20px;
}

.proposals .heading .text_field {
    margin: unset;
    width: 400px;
}

.proposals .cards_content {
    background: rgba(255, 255, 255, 0.14);
    -webkit-backdrop-filter: blur(100px);
            backdrop-filter: blur(100px);
    border-radius: 10px;
    margin-top: 16px;
    padding: 38px;
}

.proposals .cards {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.proposals .card {
    background: #FFFFFF;
    border-radius: 20px;
    color: #000000;
    padding: 18px 28px;
    width: 31%;
    text-align: left;
    position: relative;
    margin-bottom: 20px;
    margin-right: 40px;
    cursor: pointer;
}

.proposals .card:nth-child(3n) {
    margin-right: unset;
}

.proposals .card .number {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    left: -20px;
    top: 16px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
}

.proposals .card_heading {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 14px;
}

.proposals .card_heading > h2 {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    color: #000000;
    margin: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.proposals .card_heading .icon {
    width: 32px;
}

.proposals .card_heading .details {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.proposals .card_heading .details > p {
    font-family: 'Blinker', sans-serif;
    font-size: 14px;
    line-height: 130%;
    text-align: right;
    color: #696969;
    width: 100%;
    margin-left: 10px;
}

.proposals .card_heading .details > button {
    background: #EAEAEA;
    border: 1px solid #CFCFCF;
    box-sizing: border-box;
    border-radius: 100px;
    box-shadow: unset;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    margin-left: 10px;
    padding: 6px 20px;
}

.proposals .card_heading .vote_button {
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: linear-gradient(
            93.41deg, #FC5C7D 4.98%, #6A82FB 103.86%);
    border-radius: 100px;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    text-transform: unset;
    padding: 6px 30px;
}

.proposals .description {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #696969;
    margin-bottom: 26px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 120px;
}

.proposals .card > .row {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 16px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.proposals .card .key_text {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #696969;
    display: -webkit-flex;
    display: flex;
}

.proposals .card .key_text .hash_text {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #696969;
    max-width: 110px;
}

.proposals .card .icon_info {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 4px;
}

.proposals .card .icon_info .icon {
    width: 18px;
    margin-right: 10px;
}

.proposals .card .value_text {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #0085FF;
    margin-left: 10px;
}

.proposals .card .status {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 20px 0;
}

.proposals .card .status > p {
    background: #02D70A;
    border: 1px solid #00A306;
    box-sizing: border-box;
    box-shadow: inset 0 4px 4px rgb(0 0 0 / 25%);
    border-radius: 100px;
    width: -webkit-max-content;
    width: max-content;
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    padding: 8px 20px;
}

.proposals .card .status.voting_period > p {
    background: #79C7FF;
    border-color: #3B9BE0;
}

.proposals .card .status.rejected > p {
    background: #FF0000;
    border-color: #C20000;
    color: #ffffff;
}

.proposals .card .vote_details {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.proposals .vote_details > div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 10px;
}

.proposals .vote_details > div > span {
    width: 16px;
    height: 16px;
    background: #02D70A;
    border-radius: 50px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    margin-right: 10px;
}

.proposals .vote_details > div.no > span {
    background: #C0C0C0;
}

.proposals .vote_details > div.option3 > span {
    background: #FF6767;
}

.proposals .vote_details > div.option4 > span {
    background: #827CE6;
}

.proposals .vote_details > div > p {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
    display: -webkit-flex;
    display: flex;
}

.proposals .pagination > nav {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.proposals .pagination li > button {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    border-radius: 4px;
}

.proposals .pagination li > button[aria-current = 'true'] {
    background: #E1E1E1;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    color: #393939;
}

.proposals .pagination li:first-child > button svg,
.proposals .pagination li:last-child > button svg {
    display: none;
}

.proposals .pagination li:first-child > button:before {
    content: 'Back';
    text-decoration: underline;
}

.proposals .pagination li:last-child > button:before {
    content: 'Next';
    text-decoration: underline;
}

@media (max-width: 1350px) {
    .proposals .card {
        width: 46%;
    }

    .proposals .card:nth-child(3n) {
        margin-right: 40px;
    }

    .proposals .card:nth-child(2n) {
        margin-right: unset;
    }
}

@media (max-width: 958px) {
    .proposals .proposals_content {
        margin-top: unset;
    }
}

@media (max-width: 769px) {
    .proposals .card {
        width: 100%;
        margin-right: unset;
    }

    .proposals .card:nth-child(3n) {
        margin-right: unset;
    }

    .proposals .cards_content {
        background: unset;
        padding: 0;
        -webkit-backdrop-filter: unset;
                backdrop-filter: unset;
        border-radius: unset;
    }
}

@media (max-width: 426px) {
    .proposals .heading {
        overflow: auto;
    }

    .proposals .card .icon_info {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
}

.of_community .snackbar {
    left: 10px;
    -webkit-transform: translatex(10px);
            transform: translatex(10px);
}

.snackbar > div {
    background: #282525;
}

.snackbar .message {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.snackbar .message > img {
    width: 30px;
    margin-right: 10px;
}


body {
    margin: 0;
    font-family: 'Reenie Beanie', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
    font-family: 'Reenie Beanie', cursive, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

